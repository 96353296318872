<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as SwiperType } from 'swiper'
import { Autoplay, Navigation } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  slots: [
    { name: 'default' },
  ],
  description: 'Slider of Images',
  fields: {
    contain: { label: 'Contain?', type: 'checkbox' },
    numberOfSlides: { label: 'Number of Slides', type: 'number', default: '"1"' },
    autoplayDelay: { label: 'Autoplay', type: 'number', enabled: false, description: 'Time in seconds you want to delay between slides' },
    showControls: { label: 'Show Controls', type: 'checkbox' },
    controlsPosition: { label: 'Controls Positions', type: 'select', options: { 'center': 'Center', 'bottom-left': 'Bottom Left', 'bottom-right': 'Bottom Right' } },
    controlsStyle: { label: 'Controls Style', type: 'select', options: { default: 'Default', chevrons: 'Chevrons Only' } },
    showNavigation: { label: 'Show Navigation Dots', type: 'checkbox' },
    showBookingWidget: { label: 'Show Booking Widget?', type: 'checkbox' },
    showBorder: { label: 'Show Border?', type: 'checkbox', default: true },
    screenHeight: { label: 'Screen Height', type: 'number', enabled: false, description: 'Sets the height based on the percentage of the screen', default: '80' },
    maxHeight: { label: 'Max Height', type: 'number', enabled: false, description: 'Sets the max height - it will never be bigger than the height viewport and is 80% the viewport if this is not enabled.' },
  },
  name: {
    label: 'Slider Container',
    group: 'Glue',
  },
  templates: [
    {
      label: 'Slider Container',
      fields: {
        autoplayDelay: { value: 1000, enabled: true },
        controlsPosition: { value: 'center' },
        controlsStyle: { value: 'default' },
        showBookingWidget: { value: true },
        showControls: { value: true },
        showNavigation: { value: false },
      },
    },
  ],
})

const swiper = ref<SwiperType | null>(null)
const scrollTop = ref(0)

const currentSlide = computed(() => {
  if (swiper.value)
    return swiper.value.realIndex

  return 0
})

const heightCalc = computed(() => {
  if (props.fields.screenHeight.enabled) {
    return {
      height: `${props.fields.screenHeight.value}vh`,
    }
  }
  return { height: 'auto' }
})

const maxHeightCalc = computed(() => {
  if (props.fields.maxHeight.enabled) {
    return {
      'max-height': `${props.fields.maxHeight.value}px`,
      'overflow': 'hidden',
    }
  }
  return { 'max-height': '80vh' }
})

const autoPlay = computed(() => {
  if (props.fields.autoplayDelay.enabled) {
    return {
      delay: props.fields.autoplayDelay.value * 1000,
      disableOnInteraction: false,
    }
  }
  return false
})

if (process.client) {
  scrollTop.value = document.documentElement.scrollTop
  window.addEventListener('scroll', () => {
    scrollTop.value = document.documentElement.scrollTop
  })
}

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}

function goToSlide(index: number) {
  if (swiper.value)
    swiper.value.slideTo(index)
}
</script>

<template>
  <div class="bg-orange-50">
    <div
      class="relative z-40 mx-auto"
      :class="{ 'container mx-auto': fields.contain.value, 'border-b-8 border-white': fields.showBorder.value }"
    >
      <div id="glue-slider-container">
        <div
          class="relative"
        >
          <div class="relative bg-orange-50">
            <div v-if=" slice.elements.length > 1" class="overflow-hidden" :style="[heightCalc, maxHeightCalc]">
              <Swiper
                :modules="[Autoplay, Navigation]"
                :autoplay="autoPlay"
                :navigation="fields.showNavigation.value"
                :slides-per-view="1"
                :loop="slice.elements.length > 1"
                @swiper="onSwiper"
              >
                <SwiperSlide
                  v-for="(s, key) in slice.elements"
                  :key="key"
                  class="relative"
                  :style="heightCalc"
                >
                  <div
                    class="overflow-hidden"
                    :style="[heightCalc, maxHeightCalc]"
                  >
                    <VoixSlice :slice="s" :slice-index="key" class="relative flex object-cover w-full h-full" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>

            <div v-else class="overflow-hidden" :style="[heightCalc, maxHeightCalc]">
              <VoixSlice
                v-for="(s, key) in slice.elements"
                :key="key" :slice="s" :slice-index="key"
                class="relative flex object-cover w-full h-full"
              />
            </div>

            <div
              v-if="slice.elements.length > 1 && fields.showControls.value"
              class="absolute z-30 bottom-0 mb-4 p-12 pointer-events-none flex space-x-3 lg:justify-between items-center"
              :class="{
                'lg:inset-0': fields.controlsPosition.value === 'center',
                'lg:-mx-24': fields.contain.value,
                'lg:left-0': fields.controlsPosition.value === 'bottom-left',
                'lg:right-0': fields.controlsPosition.value === 'bottom-right',
              }"
            >
              <button
                class="pointer-events-auto flex relative lg:z-50 top-0 bottom-0 left-0 -mt-8 justify-center items-center"
                @click="prev"
              >
                <div
                  :class="{
                    'slider-button bg-orange-50 text-gray-500':
                      fields.controlsStyle.value === 'default',
                    'text-glueblue-600': fields.controlsStyle.value === 'chevrons',
                  }"
                >
                  <svg
                    class="pointer-events-none"
                    :class="{
                      'w-7 h-7 ': fields.controlsStyle.value === 'default',
                      'w-10 h-10 ': fields.controlsStyle.value === 'chevrons',
                    }"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </button>
              <button
                class="pointer-events-auto flex relative lg:z-50 top-0 bottom-0 right-0 -mt-8 justify-center items-center"
                @click="next"
              >
                <div
                  :class="{
                    'slider-button bg-orange-50 text-gray-500':
                      fields.controlsStyle.value === 'default',
                    'text-glueblue-600': fields.controlsStyle.value === 'chevrons',
                  }"
                >
                  <svg
                    class="transform rotate-180 pointer-events-none"
                    :class="{
                      'w-7 h-7 ': fields.controlsStyle.value === 'default',
                      'w-10 h-10 ': fields.controlsStyle.value === 'chevrons',
                    }"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </button>
            </div>

            <div
              v-if="fields.showBookingWidget.value"
              class="absolute z-50 hidden md:block bottom-0 left-0 right-0 pb-0 md:p-0"
            >
              <BookingForm
                class="bg-white translate-y-[50%] p-6 md:p-6 w-full md:w-3/4 md:mx-auto md:bottom-0 md:left-0 md:right-0 relative md:absolute z-40 hidden md:mt-0 lg:block"
              />
            </div>
          </div>
          <ul
            v-if="fields.showNavigation.value"
            class="flex justify-center pt-8 space-x-3"
          >
            <li
              v-for="(s, key) in slice.elements"
              :key="key"
              class="w-1.5 h-1.5 rounded-full transition-colors duration-300 cursor-pointer"
              :class="{
                'bg-white': key + 1 === currentSlide,
                'bg-zinc-500': key + 1 !== currentSlide,
              }"
              @click="goToSlide(key + 1)"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
